import "./styles.scss";

// Smooth Scroll
const scroll = new SmoothScroll('a[href*="#"]', {speed: 250});

// Splash Flicker
let index = 1;
const time = 2500;
const terms = ['awesome', 'epic', 'better', 'robust', 'amazing'];
const flicker = document.querySelector('#js-flicker');
const interval = setInterval(() => {
	flicker.textContent = terms[index];
	index + 1 === terms.length ? index = 0 : index++;
}, time);
